import React from "react";
import SEO from "components/seo";
import { JANCZAKI } from "shared/Types/cases";
import { useCaseData } from "shared/Hooks/CasesNew/useCaseData";
import {
  ContactForm,
  ContentVideo,
  FullPhoto,
  Header,
  Hero,
  Layout,
  Navigation,
  TextBox,
  WhatWeDid,
  Video,
} from "components/CaseLayout";

const Janczaki = () => {
  const data = useCaseData(JANCZAKI);
  return (
    <Layout>
      <SEO title={data.title} description={data.seo} />
      <Hero data={data.hero} />
      <Navigation type={JANCZAKI} />
      <WhatWeDid data={data.what_we_did} />
      <Header data={data.headers.first} />
      <ContentVideo data={data.video_content.first} />
      <TextBox data={data.text_boxes.first} />
      <Header data={data.headers.second} />
      <TextBox data={data.text_boxes.second} />
      <Video data={data.video.first} />
      <FullPhoto data={data.full_images.first} />
      <FullPhoto data={data.full_images.second} />
      <TextBox data={data.text_boxes.third} />
      <Video data={data.video.second} />
      <FullPhoto data={data.full_images.third} />
      <FullPhoto data={data.full_images.fourth} />
      <TextBox data={data.text_boxes.fourth} />
      <Video data={data.video.third} />
      <FullPhoto data={data.full_images.fifth} />
      <FullPhoto data={data.full_images.sixth} />
      <TextBox data={data.text_boxes.fifth} />
      <Video data={data.video.fourth} />
      <FullPhoto data={data.full_images.seventh} />
      <FullPhoto data={data.full_images.eighth} />
      <ContactForm />
    </Layout>
  );
};

export default Janczaki;
